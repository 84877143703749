export default class PageObjectDefaultCategory {
	/**
	 * getDisplayType - get display type
	 * @param {string} href_ - current href
	 * @returns {string} display type
	 */
	getDisplayType(href_) {
		return this._hasSubString(href_, 'layer') ? 'layer' : 'default';
	}

	/**
	 * getPageType - get page type
	 * @param {Element} domElement_ - dom element
	 * @returns {string} page type
	 */
	getPageType(domElement_) {
		// check for page specific overwrites first
		if (document) {
			const pageTypeElem = document.querySelector('meta[name=pageType]');
			if (pageTypeElem) {
				const pageType = pageTypeElem.getAttribute('content');
				if (pageType && pageType != '') {
					return pageType;
				}
			}
		}

		let pageType = 'standardPage';

		let setupPageTemplate = '';
		if (window.SETUPS && window.SETUPS.get('page.template')) {
			setupPageTemplate = window.SETUPS.get('page.template');
		}

		if (setupPageTemplate == 'nextgen-configurator-page') {
			pageType = 'nextGenConfig';
		} else if (setupPageTemplate == 'nextgen-configurator-summary') {
			pageType = 'nextGenConfigSummary';
		} else if (this._hasElement(document, '.nm-homepage')) {
			pageType = 'homepage';
		} else if (this._hasElement(document, '#nm_mofi')) {
			pageType = 'modelFinder';
		} else if (
			!!domElement_ &&
			domElement_.querySelectorAll &&
			this._hasElement(domElement_, '.nm-content')
		) {
			if (
				this._getDataType(
					domElement_.querySelectorAll('.nm-content')[0],
				) === 'nemo'
			) {
				/* PONG-335, do not continue in case of in-page navigation*/
				if (!this._isInPageNavigation(domElement_, setupPageTemplate)) {
					pageType = this._getPageTypeForNemo(
						domElement_.querySelectorAll('.nm-content')[0],
					);
				}
			} else {
				if (
					this._getDataTemplate(
						domElement_.querySelectorAll('.nm-content')[0],
					) === 'articlePage'
				) {
					pageType = 'articlePage';
				}
			}
		}

		return pageType;
	}

	/*
	 * _isInPageNavigation - returns true if the page is part of in-page navigation journey.
	 *  PONG-335 - This avoids inconsistency of pageType across the in-page navigation pages
	 * @param {Element} domElement_ - dom element
	 * @return {string} pageType - returns the page type
	 */
	_isInPageNavigation(domElement_, setupPageTemplate) {
		if (
			this._hasElement(
				domElement_,
				'.nm-navigation-derivative-main-list',
			) &&
			(setupPageTemplate === 'overview-page' ||
				setupPageTemplate === 'startpage')
		) {
			return true;
		}
		return false;
	}

	/*
	 * _getPageTypeForNemo - returns the pagetype for nemo scope
	 * @param {Element} domElement_ - dom element
	 * @return {string} pageType - returns the page type
	 */
	_getPageTypeForNemo(domElement_) {
		let pageType = 'configurableModel';
		let dataTemplate = this._getDataTemplate(domElement_);

		if (dataTemplate === 'home') {
			pageType = 'configurableHome';
		} else if (dataTemplate === 'summary') {
			pageType = 'configurationSummary';
		}

		return pageType;
	}
	/*
	 * _getDataType - returns the data-type value of a given element, if it exists
	 * @param {Element} domElement_ - dom element with the data-type attribute
	 * @return {string} returns the data-type attribute value or an empty string
	 */
	_getDataType(domElement_) {
		let dataType = '';

		if (domElement_ && domElement_.getAttribute('data-type')) {
			dataType = domElement_.getAttribute('data-type');
		}

		return dataType;
	}

	/*
	 * _getDataTemplate - returns the data-template value of a given element, if it exists
	 * @param {Element} domElement_ - dom element with the data-template attribute
	 * @return {string} returns the data-template attribute value or an empty string
	 */
	_getDataTemplate(domElement_) {
		let dataTemplate = '';

		if (domElement_ && domElement_.getAttribute('data-template')) {
			dataTemplate = domElement_.getAttribute('data-template');
		}

		return dataTemplate;
	}

	/*
	 * _hasElement - checks the document / a certain dom element for the occurence of a certain identifier
	 * @param {Element} element_ - the document / dom element in which to search for
	 * @param {string} identifier_ - the classname / id to search for
	 * @return {boolean} hasElement - true / false
	 */
	_hasElement(element_, identifier_) {
		return (
			element_.querySelectorAll(identifier_) &&
			element_.querySelectorAll(identifier_).length > 0
		);
	}

	/*
	 * _hasSubString - checks a string for a certain string occurence
	 * @param {string} searchString_ - the input string
	 * @param {string} needle_ - the string to search for
	 * @return {string} the substring
	 */
	_hasSubString(searchString_, needle_) {
		return searchString_.indexOf(needle_) !== -1;
	}
}
